import { ref } from "vue";

export const useAccumulativeFetcher = () => {
  const _list = ref([]);

  const runAccumulativeFetch = async (...args) => {
    return await Promise.all(_list.value.map(fn => fn(...args)));
  };

  const addFetcherToList = (fn) => _list.value.push(fn);

  return { runAccumulativeFetch, addFetcherToList };
};

