import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useVoiceTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const voiceOverallDataPieLine = ref({});
  const fetchVoiceOverallData = async ({ range, mcc }) => {
    voiceOverallDataPieLine.value = await chartService.v3.getFilteredVoiceDataPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchVoiceOverallData);

  const voiceCSDataPieLine = ref({});
  const fetchVoiceCSDataPieLine = async ({ range, mcc }) => {
    const voiceType = "CS";
    voiceCSDataPieLine.value = await chartService.v3.getFilteredVoiceDataPieLine({ from: range.from, to: range.to, mcc, voiceType });
  };
  addFetcherToList(fetchVoiceCSDataPieLine);

  const voiceLTEDataPieLine = ref({});
  const fetchVoiceLTEDataPieLine = async ({ range, mcc }) => {
    const voiceType = "VoLTE";
    voiceLTEDataPieLine.value = await chartService.v3.getFilteredVoiceDataPieLine({ from: range.from, to: range.to, mcc, voiceType });
  };
  addFetcherToList(fetchVoiceLTEDataPieLine);

  const voiceMODataPieLine = ref({});
  const fetchVoiceMODataPieLine = async ({ range, mcc }) => {
    const direction = "mo";
    voiceMODataPieLine.value = await chartService.v3.getFilteredVoiceDataPieLine({ from: range.from, to: range.to, mcc, direction });
  };
  addFetcherToList(fetchVoiceMODataPieLine);

  const voiceMTDataPieLine = ref({});
  const fetchVoiceMTDataPieLine = async ({ range, mcc }) => {
    const direction = "mt";
    voiceMTDataPieLine.value = await chartService.v3.getFilteredVoiceDataPieLine({ from: range.from, to: range.to, mcc, direction });
  };
  addFetcherToList(fetchVoiceMTDataPieLine);

  const voiceDataForMap = ref({});
  const fetchVoiceDataForMap = async ({ range, mcc, voiceType, testStatusId }) => {
    voiceDataForMap.value = await chartService.v3.getVoiceDataForMap({ from: range.from, to: range.to, mcc, voiceType, result: testStatusId });
  };
  addFetcherToList(fetchVoiceDataForMap);

  const voiceDataStackedBar = ref({});
  const fetchVoiceDataStackedBar = async ({ range, voiceType, testStatusId }) => {
    voiceDataStackedBar.value = await chartService.v3.getVoiceDataForStackedBar({ from: range.from, to: range.to, voiceType, result: testStatusId });
  };
  addFetcherToList(fetchVoiceDataStackedBar);

  const voiceDataForLine = ref({});
  const fetchVoiceDataForLine = async ({ range, mcc, voiceType, testStatusId }) => {
    voiceDataForLine.value = await chartService.v3.getVoiceDataForLine({ from: range.from, to: range.to, voiceType, mcc, result: testStatusId });
  };
  addFetcherToList(fetchVoiceDataForLine);

  const fetchOnMccChange = (...args) => Promise.all([
    fetchVoiceOverallData(...args),
    fetchVoiceCSDataPieLine(...args),
    fetchVoiceLTEDataPieLine(...args),
    fetchVoiceMTDataPieLine(...args),
    fetchVoiceMODataPieLine(...args),
    fetchVoiceDataForLine(...args),
    fetchVoiceDataForMap(...args)
  ]);

  const fetchOnVoiceTypeChange = (...args) => Promise.all([
    fetchVoiceDataForLine(...args),
    fetchVoiceDataStackedBar(...args),
    fetchVoiceDataForMap(...args),
  ]);

  const fetchOnTestStatusChange = (...args) => fetchOnVoiceTypeChange(...args);

  return {
    voiceOverallDataPieLine,
    fetchVoiceOverallData,

    voiceCSDataPieLine,
    fetchVoiceCSDataPieLine,

    voiceLTEDataPieLine,
    fetchVoiceLTEDataPieLine,

    voiceMODataPieLine,
    fetchVoiceMODataPieLine,

    voiceMTDataPieLine,
    fetchVoiceMTDataPieLine,

    voiceDataForMap,
    fetchVoiceDataForMap,

    voiceDataStackedBar,
    fetchVoiceDataStackedBar,

    voiceDataForLine,
    fetchVoiceDataForLine,

    fetchOnMccChange,
    fetchOnVoiceTypeChange,
    fetchOnTestStatusChange,
    runAccumulativeFetch,
  };
};
