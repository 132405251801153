import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";


export const useOverviewTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const testResultsPieLine = ref({});
  const fetchTestResultsForPieLine = async ({ range }) => {
    testResultsPieLine.value = await chartService.v3.getTestResultsForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchTestResultsForPieLine);

  const attachDataPieLine = ref({});
  const fetchAttachDataForPieLine = async ({ range }) => {
    attachDataPieLine.value = await chartService.v3.getAttachmentsForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchAttachDataForPieLine);

  const voiceDataPieLine = ref({});
  const fetchVoiceDataPieLine = async ({ range }) => {
    voiceDataPieLine.value = await chartService.v3.getVoicesForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchVoiceDataPieLine);

  const dataSessionsPieLine = ref({});
  const fetchDataSessionsPieLine = async ({ range }) => {
    dataSessionsPieLine.value = await chartService.v3.getDataSessionsForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchDataSessionsPieLine);

  const smsDataPieLine = ref({});
  const fetchSMSDataPieLine = async ({ range }) => {
    smsDataPieLine.value = await chartService.v3.getSMSForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchSMSDataPieLine);

  const testResultsStackedBar = ref({});
  const fetchTestResultsStackedBar = async ({ range }) => {
    testResultsStackedBar.value = await chartService.v3.getTestResultsForStackedBar({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchTestResultsStackedBar);

  const testResultsLineChart = ref({});
  const fetchTestResultsLineChart = async ({ range }) => {
    testResultsLineChart.value = await chartService.v3.getTestResultsForLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchTestResultsLineChart);

  const testResultsMap = ref({});
  const fetchTestResultsMap = async ({ range }) => {
    testResultsMap.value = await chartService.v3.getTestResultsForMap({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchTestResultsMap);

  const speedDataPieLine = ref({});
  const fetchSpeedDataPieLine = async ({ range }) => {
    speedDataPieLine.value = await chartService.v3.getSpeedDataForPieLine({ from: range.from, to: range.to });
  };
  addFetcherToList(fetchSpeedDataPieLine);

  return {
    testResultsPieLine,
    fetchTestResultsForPieLine,

    attachDataPieLine,
    fetchAttachDataForPieLine,

    dataSessionsPieLine,
    fetchDataSessionsPieLine,

    voiceDataPieLine,
    fetchVoiceDataPieLine,

    smsDataPieLine,
    fetchSMSDataPieLine,

    testResultsStackedBar,
    fetchTestResultsStackedBar,

    testResultsLineChart,
    fetchTestResultsLineChart,

    testResultsMap,
    fetchTestResultsMap,

    speedDataPieLine,
    fetchSpeedDataPieLine,

    runAccumulativeFetch,
  };
};
