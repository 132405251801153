import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useSMSTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const smsOverallPieLine = ref({});
  const fetchSmsOverallPieLine = async ({ range, mcc = "" }) => {
    smsOverallPieLine.value = await chartService.v3.getFilteredSMSDataForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchSmsOverallPieLine);

  const smsOnNetMOPieLine = ref({});
  const fetchSmsOnNetMOPieLine = async ({ range, mcc = "" }) => {
    const direction = "mo";
    const net = "on"
    smsOnNetMOPieLine.value = await chartService.v3.getFilteredSMSDataForPieLine({ from: range.from, to: range.to, mcc, direction, net });
  };
  addFetcherToList(fetchSmsOnNetMOPieLine);

  const smsOnNetMTPieLine = ref({});
  const fetchSmsOnNetMTPieLine = async ({ range, mcc = "" }) => {
    const direction = "mt";
    const net = "on"
    smsOnNetMTPieLine.value = await chartService.v3.getFilteredSMSDataForPieLine({ from: range.from, to: range.to, direction, net, mcc });
  };
  addFetcherToList(fetchSmsOnNetMTPieLine);

  const smsOffNetMOPieLine = ref({});
  const fetchSmsOffNetMOPieLine = async ({ range, mcc = "" }) => {
    const direction = "mo";
    const net = "off"
    smsOffNetMOPieLine.value = await chartService.v3.getFilteredSMSDataForPieLine({ from: range.from, to: range.to, direction, net, mcc });
  };
  addFetcherToList(fetchSmsOffNetMOPieLine);

  const smsOffNetMTPieLine = ref({});
  const fetchSmsOffNetMTPieLine = async ({ range, mcc = "" }) => {
    const direction = "mt";
    const net = "off"
    smsOffNetMTPieLine.value = await chartService.v3.getFilteredSMSDataForPieLine({ from: range.from, to: range.to, direction, net, mcc });
  };
  addFetcherToList(fetchSmsOffNetMTPieLine);

  const smsDataForMap = ref({});
  const fetchSMSDatForMap = async ({ range, mcc = "", testStatusId = "" }) => {
    smsDataForMap.value = await chartService.v3.getSMSDataForMap({ from: range.from, to: range.to, mcc, result: testStatusId });
  };
  addFetcherToList(fetchSMSDatForMap)

  const smsByCountryStackedBar = ref({});
  const fetchSmsByCountryStackedBar = async ({ range, testStatusId = "" }) => {
    smsByCountryStackedBar.value = await chartService.v3.getSMSDataForStackedBar({ from: range.from, to: range.to, result: testStatusId });
  };
  addFetcherToList(fetchSmsByCountryStackedBar);

  const smsTestResultsLineChart = ref({});
  const fetchSmsTestResultsLineChart = async ({ range, mcc = "", testStatusId = "" }) => {
    smsTestResultsLineChart.value = await chartService.v3.getSMSDataForLine({ from: range.from, to: range.to, result: testStatusId, mcc });
  };
  addFetcherToList(fetchSmsTestResultsLineChart);

  const fetchOnMCCChange = (...args) => Promise.all([
    fetchSmsOverallPieLine(...args),
    fetchSmsOnNetMOPieLine(...args),
    fetchSmsOnNetMTPieLine(...args),
    fetchSmsOffNetMOPieLine(...args),
    fetchSmsOffNetMTPieLine(...args),
    fetchSmsTestResultsLineChart(...args),
  ]);

  const fetchOnTestStatusChange = (...args) => Promise.all([
    fetchSMSDatForMap(...args),
    fetchSmsByCountryStackedBar(...args),
    fetchSmsTestResultsLineChart(...args),
  ]);

  return {
    smsOverallPieLine,
    fetchSmsOverallPieLine,

    smsOnNetMOPieLine,
    fetchSmsOnNetMOPieLine,

    smsOnNetMTPieLine,
    fetchSmsOnNetMTPieLine,

    smsOffNetMOPieLine,
    fetchSmsOffNetMOPieLine,

    smsOffNetMTPieLine,
    fetchSmsOffNetMTPieLine,

    smsDataForMap,
    fetchSMSDatForMap,

    smsByCountryStackedBar,
    fetchSmsByCountryStackedBar,

    smsTestResultsLineChart,
    fetchSmsTestResultsLineChart,

    fetchOnTestStatusChange,
    fetchOnMCCChange,
    runAccumulativeFetch,
  };
};
