import { ref, computed } from "vue";

// export type TabData = {
//   name: string;
//   isDisabled: boolean;
//   isActive?: boolean;
// };

export const UI_TABS_PROVIDE_KEY = Symbol("ui-tabs-provide");
// as InjectionKey<{
//   addTab: (data: TabData) => void;
//   activeTab: Readonly<Ref<string>>;
// }>;

// export function useTabs(activeTab: Ref<string>) {
export function useTabs(activeTab) {
  // const tabsDataList = ref<TabData[]>([]);
  const tabsDataList = ref([]);

  const currentTabIdx = computed(() => {
    return tabsDataList.value.findIndex((tab) => tab.name === activeTab.value);
  });

  // const addTab = (data: TabData) => {
  const addTab = (data) => {
    tabsDataList.value.push(data);
  };

  return {
    tabsDataList,
    currentTabIdx,
    addTab,
  };
}
