import { ref } from "vue";
import { chartService } from "@/api";
import { useTableApi } from "@/composition/tables/use-table-api";
import { useNotifications } from "@/composition/notifications"

export const TEST_RESULTS_COLUMNS_MINI = [
  { name: "Test Name", key: "testCase.name" },
  { name: "Subscriber/Explorer", key: "subscriber" },
  { name: "Start time", key: "startTime" },
  { name: "End time", key: "endTime" },
  { name: "Test Status", key: "status"  },
  { name: "Result info", key: "resultInfo" },
];

export const useMiniTable = ({ 
  params, 
  fetchTable,
}) => {
  const { 
    meta,
    updateMeta,
    updateTableParams: _updateTableParams,
    retrieveMeta,
    tableParams,
  } = useTableApi({ tableParams: params });

  const { showNotification } = useNotifications();

  const isLoading = ref(false);
  const data = ref([]);
  
  const updateTable = async (_params, { isSilent = false } = {}) => {
    if (!isSilent) {
      isLoading.value = true;
    }

    try {
      const resp = await fetchTable(_params);
      data.value = resp.records || [];

      if (data.value.length) {
        updateMeta(retrieveMeta(resp));
      }
    } catch (e) {
      data.value = [];
      showNotification({ message: "Failed to fetch data for the table." });
      throw e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateTableParams = async (newParams, ...args) => {
    _updateTableParams(newParams);
    await updateTable(newParams, ...args);
  };

  return {
    meta,
    updateTableParams,
    isLoading,
    data,
    updateTable,
    tableParams
  };
};

export const useAttachMiniTable = ({ params }) => {
  const {
    isLoading: isAttachmentsTableLoading,
    data: attachmentsTable,
    updateTable: updateAttachmentsTable, 
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getAttachmentsTable,
  });

  return {
    isAttachmentsTableLoading,
    attachmentsTable,
    updateAttachmentsTable,
    ...rest
  };
};

export const useVoiceMiniTable = ({ params }) => {
  const {
    isLoading: isVoiceTableLoading,
    data: voiceDataTable,
    updateTable: updateVoiceTable,
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getVoiceTable,
  });

  return {
    isVoiceTableLoading,
    voiceDataTable,
    updateVoiceTable,
    ...rest
  };
};

export const useDataSessionMiniTable = ({ params }) => {
  const {
    isLoading: isDataSessionTableLoading,
    data: dataSessionTable,
    updateTable: updateDataSessionTable,
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getDataSessionTable,
  });

  return {
    isDataSessionTableLoading,
    dataSessionTable,
    updateDataSessionTable,
    ...rest,
  };
};

export const useSMSMiniTable = ({ params }) => {
  const {
    isLoading: isSMSTableLoading,
    data: smsTable,
    updateTable: updateSMSTable,
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getSMSDataForTable,
  });

  return {
    isSMSTableLoading,
    smsTable,
    updateSMSTable,
    ...rest,
  };

};

export const useCountriesMiniTable = ({ params }) => {
  const {
    isLoading: isCountriesTableLoading,
    data: countriesTable,
    updateTable: updateCountriesTable,
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getTestResultsTable,
  });

  return {
    isCountriesTableLoading,
    countriesTable,
    updateCountriesTable,
    ...rest,
  };
};

export const useServicesMiniTable = ({ params }) => {
  const {
    isLoading: isServicesTableLoading,
    data: servicesTable,
    updateTable: updateServicesTable,
    ...rest
  } = useMiniTable({
    params,
    fetchTable: chartService.v3.getTestResultsTable,
  });

  return {
    isServicesTableLoading,
    servicesTable,
    updateServicesTable,
    ...rest,
  };  
}
