import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useDataSessionTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const dataSessionOverallPieLine = ref({});
  const fetchDataSessionOverallPieLine = async ({ range, mcc = "" }) => {
    dataSessionOverallPieLine.value = await chartService.v3.getFilteredDataSessionPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchDataSessionOverallPieLine);

  const data5GAttachedPieLine = ref({});
  const fetchData5GAttachedPieLine = async ({ range, mcc = "" }) => {
    const rat = "5G";
    data5GAttachedPieLine.value = await chartService.v3.getFilteredDataSessionPieLine({ from: range.from, to: range.to, mcc, rat });
  };
  addFetcherToList(fetchData5GAttachedPieLine);

  const data4GAttachedPieLine = ref({});
  const fetchData4GAttachedPieLine = async ({ range, mcc = "" }) => {
    const rat = "4G";
    data4GAttachedPieLine.value = await chartService.v3.getFilteredDataSessionPieLine({ from: range.from, to: range.to, mcc, rat });
  };
  addFetcherToList(fetchData4GAttachedPieLine);

  const data3GAttachedPieLine = ref({});
  const fetchData3GAttachedPieLine = async ({ range, mcc = "" }) => {
    const rat = "3G";
    data3GAttachedPieLine.value = await chartService.v3.getFilteredDataSessionPieLine({ from: range.from, to: range.to, mcc, rat });
  };
  addFetcherToList(fetchData3GAttachedPieLine);

  const data2GAttachedPieLine = ref({});
  const fetchData2GAttachedPieLine = async ({ range, mcc = "" }) => {
    const rat = "2G";
    data2GAttachedPieLine.value = await chartService.v3.getFilteredDataSessionPieLine({ from: range.from, to: range.to, mcc, rat });
  };
  addFetcherToList(fetchData2GAttachedPieLine);

  const dataSessionForMap = ref({});
  const fetchDataSessionForMap = async ({ range, mcc = "", rat = "", testStatusId = "" }) => {
    dataSessionForMap.value = await chartService.v3.getDataSessionForMap({ from: range.from, to: range.to, mcc, rat, result: testStatusId });
  };
  addFetcherToList(fetchDataSessionForMap);

  const dataSessionStackedBar = ref({});
  const fetchDataSessionStackedBar = async ({ range, rat = "", testStatusId = "" }) => {
    dataSessionStackedBar.value = await chartService.v3.getDataSessionsForStackedBar({ from: range.from, to: range.to, rat, result: testStatusId });
  };
  addFetcherToList(fetchDataSessionStackedBar);

  const dataSessionLineChart = ref({});
  const fetchDataSessionLineChart = async ({ range, mcc = "", rat = "", testStatusId = "" }) => {
    dataSessionLineChart.value = await chartService.v3.getDataSessionsLineChart({ from: range.from, to: range.to, mcc, rat, result: testStatusId });
  };
  addFetcherToList(fetchDataSessionLineChart);

  const fetchOnMCCChange = (...args) => Promise.all([
    fetchDataSessionOverallPieLine(...args),
    fetchData5GAttachedPieLine(...args),
    fetchData4GAttachedPieLine(...args),
    fetchData3GAttachedPieLine(...args),
    fetchData2GAttachedPieLine(...args),
    fetchDataSessionForMap(...args),
    fetchDataSessionLineChart(...args),
  ]);

  const fetchOnRATChange = (...args) => Promise.all([
    fetchDataSessionForMap(...args),
    fetchDataSessionStackedBar(...args),
    fetchDataSessionLineChart(...args),
  ]);

  const fetchOnTestStatusChange = (...args) => fetchOnRATChange(...args);

  return {
    dataSessionOverallPieLine,
    fetchDataSessionOverallPieLine,

    data5GAttachedPieLine,
    fetchData5GAttachedPieLine,

    data4GAttachedPieLine,
    fetchData4GAttachedPieLine,

    data3GAttachedPieLine,
    fetchData3GAttachedPieLine,

    data2GAttachedPieLine,
    fetchData2GAttachedPieLine,

    dataSessionForMap,
    fetchDataSessionForMap,

    dataSessionStackedBar,
    fetchDataSessionStackedBar,

    dataSessionLineChart,
    fetchDataSessionLineChart,

    fetchOnMCCChange,
    fetchOnRATChange,
    fetchOnTestStatusChange,
    runAccumulativeFetch,
  };
};
