import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useAttachTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const attachmentsDataLine = ref({});
  const fetchAttachmentsDataLine = async ({ mcc = "", rat = "", testStatusId = "", range }) => {
    attachmentsDataLine.value = await chartService.v3.getAttachmentsForLine({ mcc, rat, result: testStatusId, from: range.from, to: range.to });
  };
  addFetcherToList(fetchAttachmentsDataLine);

  const attachmentsOverallDataPieLine = ref({});
  const fetchAttachmentsOverallDataPieLine = async ({ mcc = "", range }) => {
    attachmentsOverallDataPieLine.value = await chartService.v3.getFilteredAttachmentsForPieLine({ mcc, from: range.from, to: range.to });
  };
  addFetcherToList(fetchAttachmentsOverallDataPieLine);

  const attachments5GDataPieLine = ref({});
  const fetchAttachments5GPieLine = async ({ mcc = "", range }) => {
    const rat = "5G";
    attachments5GDataPieLine.value = await chartService.v3.getFilteredAttachmentsForPieLine({ mcc, from: range.from, to: range.to, rat  });
  };
  addFetcherToList(fetchAttachments5GPieLine);

  const attachments4GDataPieLine = ref({});
  const fetchAttachments4GPieLine = async ({ mcc = "", range }) => {
    const rat = "4G";
    attachments4GDataPieLine.value = await chartService.v3.getFilteredAttachmentsForPieLine({ mcc, from: range.from, to: range.to, rat  });
  };
  addFetcherToList(fetchAttachments4GPieLine);

  const attachments3GDataPieLine = ref({});
  const fetchAttachments3GPieLine = async ({ mcc = "", range }) => {
    const rat = "3G";
    attachments3GDataPieLine.value = await chartService.v3.getFilteredAttachmentsForPieLine({ mcc, from: range.from, to: range.to, rat  });
  };
  addFetcherToList(fetchAttachments3GPieLine);

  const attachments2GDataPieLine = ref({});
  const fetchAttachments2GPieLine = async ({ mcc = "", range }) => {
    const rat = "2G";
    attachments2GDataPieLine.value = await chartService.v3.getFilteredAttachmentsForPieLine({ mcc, from: range.from, to: range.to, rat  });
  };
  addFetcherToList(fetchAttachments2GPieLine);

  const attachmentsDataForMap = ref({});
  const fetchAttachmentsDataForMap = async ({ mcc = "", rat = "", testStatusId = "", range }) => {
    attachmentsDataForMap.value = await chartService.v3.getAttachmentsForMap({ mcc, rat, result: testStatusId, from: range.from, to: range.to });
  };
  addFetcherToList(fetchAttachmentsDataForMap);

  const attachmentsDataStackedBar = ref({});
  const fetchAttachmentsDataStackedBar = async ({ rat = "", testStatusId = "", range }) => {
    attachmentsDataStackedBar.value = await chartService.v3.getAttachmentsForStackedBar({ rat, result: testStatusId, from: range.from, to: range.to });
  };
  addFetcherToList(fetchAttachmentsDataStackedBar);


  const fetchOnMCCChange = async (...args) => {
    await Promise.all([
      fetchAttachmentsDataLine(...args),
      fetchAttachmentsOverallDataPieLine(...args),
      fetchAttachments5GPieLine(...args),
      fetchAttachments4GPieLine(...args),
      fetchAttachments3GPieLine(...args),
      fetchAttachments2GPieLine(...args),
      fetchAttachmentsDataForMap(...args),
    ]);
  };

  const fetchOnTestStatusRATChange = async (...args) => {
    await Promise.all([
      fetchAttachmentsDataLine(...args),
      fetchAttachmentsDataForMap(...args),
      fetchAttachmentsDataStackedBar(...args),
    ]);
  };

  return {
    attachmentsDataLine,
    fetchAttachmentsDataLine,

    attachmentsOverallDataPieLine,
    fetchAttachmentsOverallDataPieLine,

    attachments5GDataPieLine,
    fetchAttachments5GPieLine,

    attachments4GDataPieLine,
    fetchAttachments4GPieLine,

    attachments3GDataPieLine,
    fetchAttachments3GPieLine,

    attachments2GDataPieLine,
    fetchAttachments2GPieLine,

    attachmentsDataForMap,
    fetchAttachmentsDataForMap,

    attachmentsDataStackedBar,
    fetchAttachmentsDataStackedBar,

    fetchOnMCCChange,
    fetchOnTestStatusRATChange,
    runAccumulativeFetch,
  };
};
