import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useCountriesTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const countriesOverallPieLine = ref({});
  const fetchCountriesOverallPieLine = async ({ range, mcc = "" }) => {
    countriesOverallPieLine.value = await chartService.v3.getTestResultsForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesOverallPieLine);

  const countriesAttachPieLine = ref({});
  const fetchCountriesAttachPieLine = async ({ range, mcc = "" }) => {
    countriesAttachPieLine.value = await chartService.v3.getAttachmentsForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesAttachPieLine);

  const countriesVoicePieLine = ref({});
  const fetchCountriesVoicePieLine = async ({ range, mcc = "" }) => {
    countriesVoicePieLine.value = await chartService.v3.getVoicesForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesVoicePieLine);

  const countriesDataPieLine = ref({});
  const fetchCountriesDataPieLine = async ({ range, mcc = "" }) => {
    countriesDataPieLine.value = await chartService.v3.getDataSessionsForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesDataPieLine);

  const countriesSMSPieLine = ref({});
  const fetchCountriesSMSPieLine = async ({ range, mcc = "" }) => {
    countriesSMSPieLine.value = await chartService.v3.getSMSForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesSMSPieLine);

  const countriesSpeedPieLine = ref({});
  const fetchCountriesSpeedPieLine = async ({ range, mcc = "" }) => {
    countriesSpeedPieLine.value = await chartService.v3.getSpeedDataForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchCountriesSpeedPieLine);

  const countriesMapData = ref({});
  const fetchCountriesMapData = async ({ range, mcc = "", testStatusId = "" }) => {
    countriesMapData.value = await chartService.v3.getTestResultsForMap({ from: range.from, to: range.to, mcc, result: testStatusId });
  };
  addFetcherToList(fetchCountriesMapData);

  const countriesDataStackedBar = ref({});
  const fetchCountriesDataStackedBar = async ({ range, testStatusId = "" }) => {
    countriesDataStackedBar.value = await chartService.v3.getTestResultsForStackedBar({ from: range.from, to: range.to, result: testStatusId });
  };
  addFetcherToList(fetchCountriesDataStackedBar);

  const fetchOnMCCChange = (...args) => Promise.all([
    fetchCountriesOverallPieLine(...args),
    fetchCountriesAttachPieLine(...args),
    fetchCountriesVoicePieLine(...args),
    fetchCountriesDataPieLine(...args),
    fetchCountriesSMSPieLine(...args),
    fetchCountriesSpeedPieLine(...args),
    fetchCountriesMapData(...args),
  ]);

  const fetchOnTestStatusChange = (...args) => Promise.all([
    fetchCountriesMapData(...args),
    fetchCountriesDataStackedBar(...args),
  ]);


  return {
    countriesOverallPieLine,
    fetchCountriesOverallPieLine,

    countriesAttachPieLine,
    fetchCountriesAttachPieLine,

    countriesVoicePieLine,
    fetchCountriesVoicePieLine,

    countriesDataPieLine,
    fetchCountriesDataPieLine,

    countriesSMSPieLine,
    fetchCountriesSMSPieLine,

    countriesSpeedPieLine,
    fetchCountriesSpeedPieLine,

    countriesMapData,
    fetchCountriesMapData,

    countriesDataStackedBar,
    fetchCountriesDataStackedBar,

    fetchOnMCCChange,
    fetchOnTestStatusChange,
    runAccumulativeFetch,
  };
};
