import { ref, computed } from "vue";
import { getUnixFromToByRange } from "@/js/general";
import { RATs, TEST_STEP_ID_TXT_LIST, VOICE_TYPES } from "@/composition/dashboard/constants";

const ALL_FILTER_OPTION = {
  key: "all",
  value: "All",
};

const ALL_MCC_OPTION = {
  countryCode: "All",
  mcc: 0,
};

const createMCCToCountryMap = (mccList) => {
  return mccList.reduce((acc, curr) => {
    acc[curr.mcc] = curr;
    return acc;
  }, {});
};

export const useDashboardFilters = ({ mccListRef = ref([]), mncDictRef = ref({}), rangeRef } = {}) => {
  const rangeInSecForReq = computed(() => getUnixFromToByRange(rangeRef.value));
  const mccToCountryCodeMap = computed(() => createMCCToCountryMap(mccListRef.value));
  const currentMCC = ref(ALL_MCC_OPTION.mcc);
  const mccReqParam = computed(() => currentMCC.value === ALL_MCC_OPTION.mcc ? "" : currentMCC.value);
  const mccSelectOptions = computed(() => [ALL_MCC_OPTION].concat(mccListRef.value));
  const getCountryCodeByMCC = (mcc) => mccToCountryCodeMap.value[mcc.toString()]?.countryCode || "N/A";
  const getOperatorByMNC = (mcc, mnc) => {
    let notFound = "?"
    if (!mncDictRef.value[mcc]) {
      return notFound;
    }
    const operatorData = mncDictRef.value[mcc].find((od) => parseInt(od.mnc) === parseInt(mnc));
    if (!operatorData) {
      return notFound;
    }
    return operatorData.name;
  }

  const currentRat = ref(ALL_FILTER_OPTION.value);
  const ratReqParam = computed(() => currentRat.value === ALL_FILTER_OPTION.value ? "" : currentRat.value);
  const ratSelectOptions = computed(() => [ALL_FILTER_OPTION.value].concat(RATs));

  const currentTestStatus = ref(ALL_FILTER_OPTION.key);
  const testStatusReqParam = computed(() => currentTestStatus.value === ALL_FILTER_OPTION.key ? "" : currentTestStatus.value)
  const testStatusSelectOptions = computed(() => [ALL_FILTER_OPTION].concat(TEST_STEP_ID_TXT_LIST));


  const currentVoiceType = ref(ALL_FILTER_OPTION.value);
  const voiceTypeReqParam = computed(() => currentVoiceType.value === ALL_FILTER_OPTION.value ? "" : currentVoiceType.value);
  const voiceSelectOptions = computed(() => [ALL_FILTER_OPTION.value].concat(VOICE_TYPES))

  return {
    rangeInSecForReq,

    currentMCC,
    mccReqParam,
    mccSelectOptions,
    getCountryCodeByMCC,
    mccToCountryCodeMap,

    getOperatorByMNC,

    currentRat,
    ratReqParam,
    ratSelectOptions,

    currentTestStatus,
    testStatusReqParam,
    testStatusSelectOptions,

    currentVoiceType,
    voiceTypeReqParam,
    voiceSelectOptions,
  };
};
