import { ref } from "vue";
import { useRouter } from "vue-router/composables";

export const DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: 25,
  search: "",
  sortBy: "",
  descending: false,
  historyFor: 0,
  full: false,
  from: Math.trunc(new Date(0).getTime() / 1000),
  to: Math.trunc(new Date().getTime() / 1000),
}; 

export const useTableApi = ({ 
  tableParams = {
    ...DEFAULT_PARAMS,
  }
} = {}) => {
  const router = useRouter();
  const _tableParams = ref({ ...tableParams });

  const meta = ref({
    page: 1,
    pageCount: 1,
    itemsPerPage: 25,
    totalCount: 0,
  });

  const updateMeta = (newMeta) => {
    meta.value = newMeta;
  };

  const retrieveMeta = (resp) => {
    const { page, pageCount, perPage: itemsPerPage, totalCount } = resp._metadata;

    return {
      page,
      pageCount,
      itemsPerPage,
      totalCount,
    }
  };

  const updateTableParams = (newParams) => {
    _tableParams.value = newParams;
  };

  const getTableParams = () => _tableParams.value;

  const updateParamsInURL = (newParams, exclude = []) => {
    const newRouteQuery = {
      ...router.currentRoute.query,
    };
    for (const [key, value] of Object.entries(newParams)) {
      if (exclude.length && exclude.includes(key)) {
        continue;
      }
      newRouteQuery[key] = value;
    }
    router.push({
      query: {
        ...newRouteQuery,
      }
    });
  };

  return {
    meta,
    tableParams: _tableParams,
    updateMeta,
    retrieveMeta,
    updateParamsInURL,
    updateTableParams,
    getTableParams,
  }
};
