import { ref } from "vue";
import { chartService } from "@/api";
import { DEFAULT_PARAMS } from "@/composition/tables/use-table-api";

export const ROW_HEADERS_COLUMN = { key: 'subService', name: '', width: 150 };
export const ROW_HEADERS = [
  {
    name: "Cell Attach",
    key: "cellAttach"
  },
  {
    name: "Voice MO",
    key: "voiceMo"
  },
  {
    name: "Voice MT",
    key: "voiceMt"
  },
  {
    name: "Mailbox",
    key: "mailbox"
  },
  {
    name: "SMS onNet MO",
    key: "smsOnNetMo"
  },
  {
    name: "SMS onNet MT",
    key: "smsOnNetMt"
  },
  {
    name: "SMS offNet MO",
    key: "smsOffNetMo"
  },
  {
    name: "SMS offNet MT",
    key: "smsOffNetMt"
  },
  {
    name: "Welcome SMS",
    key: "welcomeSms"
  },
  {
    name: "APN/PDN Connect",
    key: "apnPdnConnect"
  },
  {
    name: "Throughput",
    key: "throughput"
  }
];
export const MGMT_SUMMARY_TABLE_HEADERS = [
  { name: "Test", key: "overallTest", group: "total" },
  { name: "Error", key: "overallError", group: "total" },

  { name: "Test", key: "homeNetworkTest", group: "home-network" },
  { name: "Error", key: "homeNetworkError", group: "home-network" },

  { name: "Test", key: "intlRoamingTest", group: "inter-roam" },
  { name: "Error", key: "intlRoamingError", group: "inter-roam" },
];

const _top_headers = [
  { key: "total", name: "Total" },
  { key: "home-network", name: "Home Network" },
  { key: "inter-roam", name: "Internl.Roaming" },
];

const countColspan = (group) => {
  return MGMT_SUMMARY_TABLE_HEADERS.filter(header => header.group === group).length;
};

export const TOP_HEADERS_1 = [
  { key: "empty-1", name: "", colspan: 1 }
].concat(_top_headers.map(header => ({
  ...header,
  colspan: countColspan(header.key)
})));

export const TOP_HEADERS_2 = _top_headers.map(header => ({
  ...header,
  colspan: countColspan(header.key)
}));

export const useMgmtSummaryTable = () => {
  const isLoading = ref(false);
  const currentPeriodTable = ref([]);
  const previousPeriodTable = ref([]);

  const orderTable = (obj, columns) => {
    const table = [];
    for (const col of columns) {
      table.push(obj[col.key]);
    }
    return table;
  }
  
  const updateTables = async ({ range }, { isSilent = false } = {}) => {
    if (!isSilent) {
      isLoading.value = true;
    }
    try {
      const res = await chartService.v3.getMgmtSummaryTable({
        ...DEFAULT_PARAMS,
        to: range.to,
        from: range.from,
      });
      currentPeriodTable.value = orderTable(res.current, ROW_HEADERS);
      previousPeriodTable.value = orderTable(res.previous, ROW_HEADERS);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    currentPeriodTable,
    previousPeriodTable,
    updateTables,
    isLoading,
  };
};
