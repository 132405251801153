export const generateDummyLineData = (numPoints = 10) => {
  const currentTime = Date.now();
  return {
    1: Array.from({ length: numPoints }, (_, i) => [
      currentTime * (numPoints - i),
      Math.floor(Math.random() * 20) + 1,
    ]),
  };
};

