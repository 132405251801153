<script setup>
import UiDashboardLayout from "@/components/basic/layouts/UiDashboardLayout.vue";
import UiTabsContainer from "@/components/basic/tabs/UiTabsContainer.vue";
import UiTabItem from "@/components/basic/tabs/UiTabItem.vue";
import DateTimeRange from "@/components/basic/DateTimeRange.vue";
import XSelect from "@/components/basic/XSelect.vue";
import OverviewTab from "@/components/dashboard/tabs/OverviewTab.vue";
import AttachTab from "@/components/dashboard/tabs/AttachTab.vue";
import VoiceTab from "@/components/dashboard/tabs/VoiceTab.vue";
import DataSessionTab from "@/components/dashboard/tabs/DataSessionTab.vue";
import SMSTab from "@/components/dashboard/tabs/SMSTab.vue";
import CountriesTab from "@/components/dashboard/tabs/CountriesTab.vue";
import MgmtSummaryTab from "@/components/dashboard/tabs/MgmtSummaryTab.vue";
import ServicesTab from "@/components/dashboard/tabs/ServicesTab.vue";
import { ref, provide, onMounted, nextTick } from "vue";
import { useTimeRangeSelect, DAY_IN_SECONDS } from "@/composition/filtering-components/use-time-range-select"
import { chartService } from "@/api";
import { getUnixFromToByRange } from "@/js/general";

const TWO_DAYS = DAY_IN_SECONDS * 2;
const DEFAULT_RANGE = {
  seconds: TWO_DAYS,
  from: new Date((Date.now() / 1000 - TWO_DAYS) * 1000),
  to: new Date(),
};
const REFRESH_OPTIONS = [ 
  { name: "off", value: 0 },
  { name: "30s", value: 1000 * 30 },
  { name: "1m", value: 1000 * 60 },
  { name: "5m", value: 1000 * 60 * 5 },
  { name: "10m", value: 1000 * 60 * 10 },
];
const PROVIDE_KEY = "DashboardView2";


const { range } = useTimeRangeSelect({ defaultRange: DEFAULT_RANGE });
const activeTab = ref("");
const availableMCCs = ref([]);
const fetchAvailableMCCs = async () => {
  const minLastDays = 20 * DAY_IN_SECONDS * 1000;
  let r = getUnixFromToByRange(range.value);
  if (minLastDays > (r.to - r.from)) {
    r.from = r.to - minLastDays;
  }
  availableMCCs.value = (await chartService.v3.getMCCs({ from: r.from, to: r.to })).mccs || [];
};

const availableMNCs = ref({});
const fetchAvailableMNCs = async () => {
  const r = getUnixFromToByRange(range.value);
  availableMNCs.value = await chartService.v3.getMNCs({ from: r.from, to: r.to }) || {};
};

const refreshInterval = ref(0);
const refreshTimoutId = ref(null);
const refreshQueue = ref({});
const addToRefreshQueue = (key, cb) => {
  refreshQueue.value = {
    ...refreshQueue.value,
    [key]: cb,
  };
};
const removeFromRefreshQueue = (key) => {
  // eslint-disable-next-line no-unused-vars
  const { [key]: _, ...rest } = refreshQueue.value;
  refreshQueue.value = {
    ...rest,
  };
};
provide(PROVIDE_KEY, {
  addToRefreshQueue,
  removeFromRefreshQueue,
});

const recalculateRange = () => {
  if (!range.value.seconds) {
    return;
  }

  range.value = {
    seconds: range.value.seconds,
    to: new Date(),
    from: new Date((Date.now() / 1000 - range.value.seconds) * 1000)
  };
};
const runRefresh = async ({ isRangeChange = false } = {}) => {
  if (!isRangeChange) {
    recalculateRange();
  }
  await nextTick();
  const newRange = range.value;
  await Promise.all([
    fetchAvailableMCCs(),
    fetchAvailableMNCs(),
  ]).then(() => Promise.all(Object.entries(refreshQueue.value).map(([key, cb]) => cb({ key, newRange, isRangeChange }))));
};

const updateRefreshInterval = (v) => {
  refreshInterval.value = v;
  if (!v && !refreshTimoutId.value) {
    return;
  }
  if (!v && refreshTimoutId.value) {
    clearInterval(refreshTimoutId.value);
    refreshTimoutId.value = null;
    return;
  }
  refreshTimoutId.value = setInterval(runRefresh, v);
};

const updateRange = (r) => {
  range.value = r;
  runRefresh({ isRangeChange: true });
};

onMounted(async () => {
  // default refreshing is 'off';
  updateRefreshInterval(REFRESH_OPTIONS[0].value);
  await Promise.all([
    fetchAvailableMCCs(),
    fetchAvailableMNCs(),
  ]);
});

</script>

<template>
  <UiDashboardLayout
    class="dashboard-view"
  >
    <UiTabsContainer
      :active-tab="activeTab"
      align-tabs="stretch"
      @update:activeTab="(t) => activeTab = t"
      tabs-are-scrollable
    >
      <template #right-controls>
        <div class="dashboard-view__global-controls-box">
          <DateTimeRange
            :value="range"
            @input="(newRange) => updateRange(newRange)"
          />

          <XSelect
            class="dashboard-view__global-refresh-select"
            :value="refreshInterval"
            label="Refresh"
            autocomplete
            item-text="name"
            item-value="value"
            :items="REFRESH_OPTIONS"
            @input="(v) => updateRefreshInterval(v)"
          />
        </div>
      </template>

      <UiTabItem 
        name="Overview"
        key="Overview-1"
        is-active
      >
        <OverviewTab
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="Attach"
        key="Attach-2"
      >
        <AttachTab 
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="Voice"
        key="Voice-3"
      >
        <VoiceTab
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="Data"
        key="Data-session-4"
      >
        <DataSessionTab
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="SMS"
        key="SMS-tab-42"
      >
        <SMSTab
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="Countries"
        key="Countries-tab-420"
      >
        <CountriesTab
          :range="range"
          :mccs="availableMCCs"
          :mncs="availableMNCs"
        />
      </UiTabItem>

      <UiTabItem
        name="Mgmt Summary"
        key="mgmt-summ-123"
      >
        <MgmtSummaryTab
          :range="range"
        />
      </UiTabItem>

      <UiTabItem
        name="Services"
        key="services-203"
      >
        <ServicesTab
          :range="range"
          :mccs="availableMCCs"
        />
      </UiTabItem>
    </UiTabsContainer>
  </UiDashboardLayout>
</template>

<style lang="scss">
:root {
  --v-application-wrap-display: grid;
  --v-application-wrap-overflow: hidden;
  --main-v3-max-height: initial;
}

.dashboard-view {
  &__global-controls-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    height: 100%;
  }

  &__global-refresh-select {
    max-width: 120px;
  }
}
</style>
