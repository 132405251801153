import { ref } from "vue";
import { chartService } from "@/api";
import { useAccumulativeFetcher } from "@/composition/data-loading/use-accumulative-fetcher";

export const useServicesTabData = () => {
  const { runAccumulativeFetch, addFetcherToList } = useAccumulativeFetcher();

  const welcomeSMSPieLineData = ref({});
  const fetchWelcomeSMSPieLineData = async ({ mcc = "", range }) => {
    welcomeSMSPieLineData.value = await chartService.v3.getServicesWelcomeSMS({ mcc, from: range.from, to: range.to });
  };
  addFetcherToList(fetchWelcomeSMSPieLineData);

  const dataUsagePieLineData = ref({});
  const fetchDataUsagePieLineData = async ({ mcc = "", range }) => {
    dataUsagePieLineData.value = await chartService.v3.getSpeedDataForPieLine({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchDataUsagePieLineData);

  const voiceOnNetPieLineData = ref({});
  const fetchVoiceOnNetPieLineData = async ({ mcc = "", range }) => {
    voiceOnNetPieLineData.value = await chartService.v3.getVoicesForPieLine({ from: range.from, to: range.to, mcc, net: "on", direction: "" });
  };
  addFetcherToList(fetchVoiceOnNetPieLineData);

  const voiceInterconnectPieLineData = ref({});
  const fetchVoiceInterconnectPieLineData = async ({ mcc = "", range }) => {
    voiceInterconnectPieLineData.value = await chartService.v3.getServicesVoiceInterconnect({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchVoiceInterconnectPieLineData);

  const voiceInternationalPieLineData = ref({});
  const fetchVoiceInternationalPieLineData = async ({ mcc = "", range }) => {
    voiceInternationalPieLineData.value = await chartService.v3.getServicesVoiceInternational({ from: range.from, to: range.to, mcc });
  };
  addFetcherToList(fetchVoiceInternationalPieLineData);

  return {
    welcomeSMSPieLineData,
    fetchWelcomeSMSPieLineData,

    dataUsagePieLineData,
    fetchDataUsagePieLineData,

    voiceOnNetPieLineData,
    fetchVoiceOnNetPieLineData,

    voiceInterconnectPieLineData,
    fetchVoiceInterconnectPieLineData,

    voiceInternationalPieLineData, 
    fetchVoiceInternationalPieLineData,

    runAccumulativeFetch,
  };
};
