import { TEST_STATUS_MAP_BY_ID_SHORT_TXT } from "@/cfg/testStatus";
import { TEST_STEP_STATUS_MAP_SHORT_TXT } from "@/cfg/testStepStatus";

export const DEFAULT_MAP_OPTIONS = {
  center: [47, 8],
  zoom: 4,
  minZoom: 4
};

export const RATs = ["5G", "4G", "3G", "2G"];

export const VOICE_TYPES = ["CS", "VoLTE"];

// test queue
export const TEST_STATUS_IDS_TO_SHOW = [2, 3, 4, 7, 8];
export const TEST_STATUS_MAP = new Map(Array.from(TEST_STATUS_MAP_BY_ID_SHORT_TXT).filter(([id,]) => TEST_STATUS_IDS_TO_SHOW.includes(id)));
export const TEST_STATUS_LEGEND_TITLES = Array.from(TEST_STATUS_MAP).map(([, t]) => t.text);

// test step
export const TEST_STEP_STATUSES_IDS_TO_SHOW = [1,2,3,7,8];
export const TEST_STEP_STATUS_MAP = new Map(Array.from(TEST_STEP_STATUS_MAP_SHORT_TXT).filter(([id,]) => TEST_STEP_STATUSES_IDS_TO_SHOW.includes(id)))
export const TEST_STEP_STATUS_LEGEND_TITLES = Array.from(TEST_STEP_STATUS_MAP).map(([, t]) => t.text)
export const TEST_STEP_ID_TXT_LIST = Array.from(TEST_STEP_STATUS_MAP).map(([id, t]) => ({ key: id, value: t.text }));

